/* eslint-disable no-plusplus */
import { gql } from '@apollo/client';
import { modules } from '@config';

const weltpixel_labels = `
weltpixel_labels {
  categoryLabel {
      css
      customer_group
      image
      page_position
      position
      priority
      text
      text_padding
      text_bg_color
      text_font_size
      text_font_color          
  }
  productLabel {
      css
      customer_group
      image
      page_position
      position
      priority
      text
      text_padding
      text_bg_color
      text_font_size
      text_font_color  
  }
}        
`;

const productOverview = `
  promotion
  additional_info{
    info_penting
    info_pengiriman
    kebijakan_pengembalian
  }
  max_qty_allowed
`;

const productDetail = `
  id
  name
  sku
  ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
  stock_status
  url_key
  __typename
  attribute_set_id
  small_image{
    url,
    label
  }
  image{
    url
  }
  review {
    rating_summary
    reviews_count
  }
  categories {
    id
    name
    url_path
    breadcrumbs {
      category_id
      category_url_path
      category_name
    }
    event {
      event_id
      start_date
      end_date
      status
    }
  }
  special_from_date
  special_to_date
  additional_data {
    sold_qty
    label
  }
  product_detail
  garment_care
  vendor_name
`;

const priceRange = `
  price_range {
    minimum_price {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      fixed_product_taxes {
        amount {
          currency
          value
        }
        label
      }
      regular_price {
        currency
        value
      }
    }
    maximum_price {
        discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      fixed_product_taxes {
        amount {
          currency
          value
        }
        label
      }
      regular_price {
        currency
        value
      }
    }
  }
`;

const priceTiers = `
  price_tiers {
    discount {
      amount_off
      percent_off
    }
    final_price {
      currency
      value
    }
    quantity
  }
`;

const salableQty = `
  salable_qty{
    total_qty
    child_products{
      option {
        attribute_code
        label
        value
      }
      qty
    } 
  }
`;

/**
 * scema dynamic resolver url
 * @param url String
 * @returns grapql query
 */

export const getProduct = (url) => {
    const query = gql`{
        products(
            search: "" ,filter: {
              url_key: {
                eq: "${url}"
              }
            }
          ) {
            items {
              ${modules.product.customizableOptions.enabled && `
              ... on CustomizableProductInterface {
                options {
                  title
                  option_id
                  required
                  sort_order
                  __typename
                }
              }
              `}
              ${productDetail}
              ${productOverview}
              ${priceRange}
              ${priceTiers}
              ${salableQty}
              ${weltpixel_labels}
              description {
                html
              }
              ${modules.brands.enabled ? 'brand' : ''}
              short_description {
                html
              }
              more_info {
                label
                value
              }
              media_gallery {
                label,
                url
              }      
              max_discount {
                amount_off
                percent_off
              }
            }
            total_count
          }
    }`;
    return query;
};

export const getProductBySku = () => {
    const query = gql`query(
      $sku: [String]
    ){
        products(
            search: "" ,filter: {
              sku: {
                in: $sku
              }
            }
          ) {
            items {
              ${productDetail}
              ${priceRange}
              ${priceTiers}
              ${salableQty}
              description {
                html
              }
              ${modules.brands.enabled ? 'brand' : ''}
              short_description {
                html
              }
              more_info {
                label
                value
              }
              upsell_products {
                ${productDetail}
                ${priceRange}
                ${priceTiers}
              }
              media_gallery {
                label,
                url
              }
              related_products {
               ${productDetail}
               ${priceRange}
               ${priceTiers}
              }
            }
            total_count
          }
    }`;
    return query;
};

export const getProductBySkuFreeItems = () => {
    const query = gql`query(
    $sku: [String]
  ){
      products(
          search: "" ,filter: {
            sku: {
              in: $sku
            }
          }
        ) {
          items {
            ${productDetail}
            ${priceRange}
            ${priceTiers}
            ${salableQty}
            description {
              html
            }
            ${modules.brands.enabled ? 'brand' : ''}
            short_description {
              html
            }
            more_info {
              label
              value
            }
            media_gallery {
              label,
              url
            }
          }
          total_count
        }
  }`;
    return query;
};

export const addReview = gql`
    mutation createReview($nickname: String!, $rating: Int!, $title: String!, $detail: String!, $pkValue: Int!) {
        addProductReview(
            input: {
                entity_pk_value: $pkValue
                title: $title
                detail: $detail
                nickname: $nickname
                ratings: { rating_name: "Rating", value: $rating }
            }
        ) {
            message
        }
    }
`;

export const getReview = () => {
    const query = gql`
        query getReview($sku: String!, $pageSize: Int, $currentPage: Int) {
            getProductReviews(sku: $sku, pageSize: $pageSize, currentPage: $currentPage) {
                items {
                    id
                    nickname
                    ratings {
                        rating_name
                        value
                    }
                    entity_pk_value
                    review_entity
                    review_type
                    review_status
                    title
                    detail
                    created_at
                }
                message
                totalCount
            }
        }
    `;
    return query;
};

export const addWishlist = gql`
    mutation addWishlist($productId: Int!) {
        addProductToWishlist(productId: $productId) {
            info
        }
    }
`;

export const getBundleProduct = (sku) => {
    const query = gql`{
  products(
    search: "" ,filter: {
      sku: {
        eq: "${sku}"
      }
    }
  ) {
    items {
      ... on BundleProduct {
        id
        name
        url_key
        items {
          position
          option_id
          title
          type
          required
          options {
            id
            is_default
            label
            quantity
            product {
              id
              name
              price_range {
                minimum_price {
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    currency
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`;
    return query;
};

export const getDownloadProduct = (sku) => {
    const query = gql`{
    products(
      search: "" ,filter: {
        sku: {
          eq: "${sku}"
        }
      }
    ) {
      items {
        ... on DownloadableProduct {
          id
          name
          url_key
          downloadable_product_links {
            id
            uid
            title
            price
          }
        }
      }
    }
  }`;
    return query;
};

export const getConfigurableProduct = (sku) => {
    const query = gql`{
    products(
      search: "" ,filter: {
        sku: {
          eq: "${sku}"
        }
      }
    ) {
      items {
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data {
                value
                ... on ImageSwatchData {
                  thumbnail
                  value
                }
                ... on ColorSwatchData {
                  value
                }
                ... on TextSwatchData {
                  value
                }
              }
            }
            product_id
          }
          variants {
            product {
              ${productDetail}
              ${priceRange}
              ${priceTiers}
              media_gallery {
                label,
                url
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
    }
  }`;
    return query;
};

export const getGroupedProduct = gql`
    query getGroupedProduct($sku: String!) {
        products(search: "", filter: { sku: { eq: $sku } }) {
            items {
                __typename
                ... on GroupedProduct {
                    items {
                        qty
                        position
                        product {
                            id
                            name
                            sku
                            ${modules.catalog.productListing.label.sale.enabled ? 'sale' : ''}
                            stock_status
                            special_from_date
                            special_to_date
                            ${priceRange}
                            ${priceTiers}
                        }
                    }
                }
            }
        }
    }
`;

export const getProductLabel = gql`
query Products($url: String){
  products(
    search: "" ,filter: {
      url_key: {
        eq: $url
      }
    }
  ) {
    items {
      id
      __typename
      ${weltpixel_labels}
    }
  }
}
`;

export const getUpsellProducts = (url) => {
    const query = gql`{
      products(
          search: "", filter: {
            url_key: {
              eq: "${url}"
            }
          }
      ) {
        items {
          upsell_products {
            ${productDetail}
            ${priceRange}
            ${priceTiers}
            ... on ConfigurableProduct {
              variants {
                  product {
                      image{
                        url
                      }
                  }
                  attributes {
                      label
                      code
                      value_index
                  }
              }
            }
          }
        }
      }
    }`;
    return query;
};

export const getRelatedProducts = (url) => {
    const query = gql`{
      products(
          search: "", filter: {
            url_key: {
              eq: "${url}"
            }
          }
      ) {
        items {
          related_products {
            ${productDetail}
            ${priceRange}
            ${priceTiers}
            ... on ConfigurableProduct {
              variants {
                  product {
                      image{
                        url
                      }
                  }
                  attributes {
                      label
                      code
                      value_index
                  }
              }
            }
          }
        }
      }
    }`;
    return query;
};

export const getProductVariants = (sku) => {
    const query = gql`{
      products(
        search: "" ,filter: {
          sku: {
            eq: "${sku}"
          }
        }
      ) {
        items {
          ... on ConfigurableProduct {
            variants {
              product {
                image{
                  url
                }
              }
              attributes {
                label
                code
                value_index
              }
            }
          }
        }
      }
    }`;
    return query;
};

export const getFreeItemsCheckout = gql`
    query getFreeItemsCheckout($sku: [String]) {
      products(
        search: "" ,filter: {
          sku: {
            in: $sku
          }
        }
      ) {
        items {
          ${productDetail}
          ${priceRange}
          ${priceTiers}
          ${salableQty}
          description {
            html
          }
          ${modules.brands.enabled ? 'brand' : ''}
          short_description {
            html
          }
          more_info {
            label
            value
          }
          media_gallery {
            label,
            url
          }
        }
        total_count
      }
    }
`;

export const getProductQuery = (url) => {
    const query = gql`{
    customProductSearch(filter_url_key: "${url}") {
          ${modules.product.customizableOptions.enabled && `
          ... on CustomizableProductInterface {
            options {
              title
              option_id
              required
              sort_order
              __typename
            }
          }
          `}
          ${productDetail}
          ${productOverview}
          ${priceRange}
          ${priceTiers}
          ${salableQty}
          ${weltpixel_labels}
          description {
            html
          }
          ${modules.brands.enabled ? 'brand' : ''}
          short_description {
            html
          }
          more_info {
            label
            value
          }
          media_gallery {
            label,
            url
          }      
          max_discount {
            amount_off
            percent_off
          }
          meta_keyword
          meta_title
          meta_description
        }
  }`;
    return query;
};

export const getUpsellProductsQuery = (url) => {
    const query = gql`{
    customProductSearch(filter_url_key: "${url}") {
      upsell_products {
        ${productDetail}
        ${priceRange}
        ${priceTiers}
        ... on ConfigurableProduct {
          variants {
              product {
                  image{
                    url
                  }
              }
              attributes {
                  label
                  code
                  value_index
              }
          }
        }
      }
    }
  }`;
    return query;
};

export const getRelatedProductsQuery = (url) => {
    const query = gql`{
    customProductSearch(filter_url_key: "${url}") {
      related_products {
        ${productDetail}
        ${priceRange}
        ${priceTiers}
        ... on ConfigurableProduct {
          variants {
              product {
                  image{
                    url
                  }
              }
              attributes {
                  label
                  code
                  value_index
              }
          }
        }
      }
    }
  }`;
    return query;
};

export const getConfigurableProductQuery = (url) => {
    const query = gql`{
    customProductSearch(filter_url_key: "${url}") {
      ... on ConfigurableProduct {
        configurable_options {
          id
          attribute_id
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
            swatch_data {
              value
              ... on ImageSwatchData {
                thumbnail
                value
              }
              ... on ColorSwatchData {
                value
              }
              ... on TextSwatchData {
                value
              }
            }
          }
          product_id
        }
        variants {
          product {
            ${productDetail}
            ${priceRange}
            ${priceTiers}
            media_gallery {
              label,
              url
            }
          }
          attributes {
            label
            code
            value_index
          }
        }
      }
    }
}`;
    return query;
};

export const getProductVariantsQuery = (url) => {
    const query = gql`{
      customProductSearch(filter_url_key: "${url}") {
        ... on ConfigurableProduct {
          variants {
            product {
              image{
                url
              }
            }
            attributes {
              label
              code
              value_index
            }
          }
        }
      }
    }`;
    return query;
};

export default {
    getProductBySku,
    getProductBySkuFreeItems,
    getProduct,
    getProductQuery,
    getConfigurableProductQuery,
    getProductVariantsQuery,
};
